/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-3y290g css-2td8d5 --style3 --full" anim={""} name={"intro"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen_s=3000x_.jpg);
    }
  
background-position: 50% 76%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"}>
              
              <Title className="title-box fs--128 mt--08" style={{"maxWidth":1100}} content={"<span style=\"color: white;\">Professional Chimney Services</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20 mt--25" content={"List of services"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-1"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper animS={"3"} style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"A Trusted Chimney Sweep in South Moravia."}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 flex--top" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left" anim={""} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/169/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/169/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/169/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/169/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/169/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/169/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" anim={""} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/169/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/169/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/169/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/169/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/169/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/169/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-3"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--16 pt--06 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Chimney Maintenance"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Chimney Cleaning"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Mechanical Cleaning"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Lining"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Construction"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":380}}>
              
              <Subtitle className="subtitle-box" content={"Consulting"}>
              </Subtitle>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1nuglum css-42e4bw pb--60 pt--60" name={"contact"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">510-851-4014<br>info@vase-stranky.com</span><br>"}>
              </Title>

              <Button className="btn-box fs--20" content={"Booking"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Chimney Sweep</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">722 Finch Street, Asbury Park, NJ 07712<br>510-851-4014<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}